$(document).ready(function () {
    if (document.getElementsByClassName("c-carousel").length > 0) carousel();
    if (document.getElementsByClassName("c-form-field").length > 0) validationStyling();
    if (document.getElementsByClassName("c-filter__wrapper").length > 0) filterToggle();
    if (document.getElementsByClassName("c-product__tabs").length > 0) productToggle();
    if (document.getElementsByClassName("c-popup-wizard-container").length > 0) popupListener();
    colorboxWizardModal();
    tabs();
    tabHeightChecker();
    colorboxModal();

});


function popupListener() {

    $(".c-euro-cylinder-popup__close").click(function () {
        $(".c-popup-wizard-container").removeClass("c-popup-wizard-container--active");
    });
}

function loadPopup() {
    // General popup functionality
    var popup = {};

    popup.setup = function () {
        //popup.getStoredButtonCookie(popup.buttonCookieName);
        //popup.getStoredPopupCookie(popup.surveyCookieName);
        popup.bind();
        popup.hideTriggerButton();
        popup.showPopupContainer();
    };


    popup.bind = function () {
        popup.triggerPopupButton.off("click").on("click", function () {
            popup.hideTriggerButton();
            popup.showPopupContainer();
        });

        popup.closeCTA.off("click").on("click", function () {
            //popup.updateStoredPopupCookie(popup.container, true);
            popup.showTriggerButton();
            popup.hidePopupContainer();
        });

        popup.actionCTA.off("click").on("click", function () {
            //popup.updateStoredPopupCookie(popup.container, true);

        });

        popup.container.off("click").on("click", function (e) {

            if ($(e.target).hasClass(popup.containerClass)) {
                //popup.updateStoredPopupCookie(popup.container, true);
                popup.showTriggerButton();
                popup.hidePopupContainer();
            }
        });
    };

    popup.hideTriggerButton = function () {
        popup.stickyContainer.removeClass(popup.stickyContainerActiveClass);
    };

    popup.showTriggerButton = function () {
        popup.stickyContainer.addClass(popup.stickyContainerActiveClass);
    };

    popup.hidePopupContainer = function () {
        popup.container.removeClass(popup.containerActiveClass);
        popup.removeFixedPageClass();
    };

    popup.showPopupContainer = function () {
        popup.container.addClass(popup.containerActiveClass);
        popup.addFixedPageClass();
    };

    popup.addFixedPageClass = function () {
        popup.outerPage.addClass(popup.outerPageFixedClass);
    };

    popup.removeFixedPageClass = function () {
        popup.outerPage.removeClass(popup.outerPageFixedClass);
    };

    popup.init = function () {
        popup.outerPageClass = "o-outer-page";
        popup.outerPageFixedClass = "o-outer-page--fixed";
        popup.containerClass = "c-popup-wizard-containe";
        popup.containerActiveClass = popup.containerClass + "--active";

        popup.outerPage = $("." + popup.outerPageClass);
        popup.container = $("." + popup.containerClass);

        if (popup.container.length > 0) {
            popup.stickyContainer = $(".c-sticky-tab");
            popup.stickyContainerActiveClass = "c-sticky-tab--active";
            popup.triggerPopupButton = popup.stickyContainer.find(".c-button");
            popup.hidePopupButton = popup.stickyContainer.find(".c-button");

            popup.actionCTA = popup.container.find(".c-popup__cta--action");
            popup.closeCTA = popup.container.find(".c-popup__cta--hide");

            popup.buttonCookieName = "HideMemberSurveyButton";
            popup.surveyCookieName = "HideMemberSurvey";

            popup.setup();
        }
    }();
}


function updateTotalPrice() {

}



function colorboxWizardModal() {
    $('.cbox').colorbox({
        iframe: true,
        scrolling: true,
        width: '80%',
        height: '80%'
    });
}

function filterToggle() {
    $('.c-filter-mobile-icon').off().on('click', function () {
        $('.c-filter__wrapper').toggleClass("c-filter__wrapper--visible")
    });

    $('.c-filter-items__close').off().on('click', function () {
        $('.c-filter__wrapper').toggleClass("c-filter__wrapper--visible")
    })
}

function tabHeightChecker() {

    $('.c-product__tabs--height').each(function () {
        var test = $(this).height()
        if ($(this).height() < 380) {
            $(this).find(".c-product__toggle").hide()
        }
    });
}

function productToggle() {
    $('.c-product__toggle-button').off().on('click', function () {


        $('.tab_content').each(function () {
            $(this).toggleClass('tab_content--show')
        });

    })
}

function colorboxModal() {
    $(".cbox").colorbox({ iframe: true, scrolling: true, width: "40%", height: "80%" });
}

function validationStyling() {



    $('.c-form-field__input').each(function () {
        if ($(this).find('c-form__error').is(":visible")) {
            $(this).find('input').addClass('c-form-field__error')
        }
        else {
            $(this).find('input').removeClass('c-form-field__error')
        }

    });
}

function CheckoutTabChange(activeTabClick) {


    $(".tab_content").hide();
    var activeTab = activeTabClick;
    $("#" + activeTab).fadeIn();
    $("ul.tabs li").removeClass("active");
    $(this).addClass("active");
    $(".tab_drawer_heading").removeClass("d_active");
    $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass("d_active");

    var sss = $(".tabs").find("li")

    $(".tabs").find("li").each(function () {

        var element = $(this)
        var test = element.attr("rel")

        element.removeClass("active");

    })


    var test2 = $('li[rel="' + activeTabClick + '"]')
    test2.addClass("active")

    $('.c-checkout__tabs').animate({
        scrollTop: $("#" + activeTab).offset().top
    }, 2000);

}

function tabs() {
    $(".tab_content").hide();
    $(".tab_content:first").show();
    /* if in tab mode */

    $("ul.tabs li").click(function () {
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
        $(".tab_drawer_heading").removeClass("d_active");
        $(".tab_drawer_heading[rel^='" + activeTab + "']").addClass("d_active");
    });
    //$(".tab_container").css("min-height", function () {
    //    return $(".tabs").outerHeight() + 50;
    //});
    /* if in drawer mode */

    $(".tab_drawer_heading").click(function () {
        $(".tab_content").hide();
        var d_activeTab = $(this).attr("rel");
        $("#" + d_activeTab).fadeIn();
        $(".tab_drawer_heading").removeClass("d_active");
        $(this).addClass("d_active");
        $("ul.tabs li").removeClass("active");
        $("ul.tabs li[rel^='" + d_activeTab + "']").addClass("active");
    });
}


function carousel() {
    var total = $('.carousel img').length,
        rand = Math.floor(Math.random() * total);

    var container = $(".c-carousel");

    container.find(".carousel").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: container.find(' .slider-nav-container .slider-nav'),
        variableWidth: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        initialSlide: rand,
        rows: 0
    });
};





