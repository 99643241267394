/* AJAX Overlay */
/* Initialise the popup overlay window when certain CTAs are clicked */

var popout = {};
var counter = 0;

popout.bind = function () {
    popout.clickOpen();
};

popout.onLoad = function () {
    $(window).on("load", function () {

        if (window.location.search) {

            if (popout.urlParam("fdArticleType") && popout.urlParam("fdArticleId")) {

                popout.articleTypeVal = JSON.parse(popout.urlParam("fdArticleType"));
                popout.articleIdVal = JSON.parse(popout.urlParam("fdArticleId"));

                popout.openPopup();
            }
        }
    });
};

popout.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    }
    else {
        return decodeURI(results[1]) || 0;
    }
};

popout.clickOpen = function () {
    for (var i = 0; i < popout.overlayCTAOpen.length; i++) {
        var _ = this;

        popout.overlayCTAOpen.off("click").on("click", function (e) {
            e.preventDefault();

            popout.buttonClicked = true;
            popout.getButtonArticleType($(this));
            popout.getButtonArticleId($(this));

            popout.openPopup($(this));
        });
    }
};

popout.clickCloseBtn = function () {
    popout.overlayCTAClose.off("click").on("click", function (e) {
        e.preventDefault();

        popout.closePopup();
    });
};

popout.clickCloseIcon = function () {
    popout.overlayCTACloseIcon.off("click").on("click", function (e) {
        e.preventDefault();

        popout.closePopup();
    });
};

popout.clickOverlayShadow = function () {
    $("." + popout.overlayPanelContainerClass).off("click").on("click", function (e) {

        if ($(e.target).hasClass(popout.overlayPanelVisibleClass)) {
            e.preventDefault();

            popout.closePopup();
        }
    });
};

popout.openPopup = function (button) {
    popout.identifyArticleType();
    popout.getScrollPos();
    popout.assembleArticle(button);
};

popout.closePopup = function () {
    popout.outerPage.removeClass(popout.outerPageFixedClass);
    popout.removeOverlayShadow();
    popout.buttonClicked = false;

    //if (popout.bannerImage) popout.bannerImage.css("background-image", "");
    //if (popout.title) popout.title.empty();
    //if (popout.text) popout.text.empty();

    popout.overlayPanelContainer.remove();
    popout.overlayPanelContainer = "";
    popout.removeBodyScrollPos();
    popout.setScrollPos();
};

popout.getButtonArticleType = function (elem) {
    popout.articleTypeVal = JSON.parse($(elem).attr("data-popout-article-type"));
};

popout.getButtonArticleId = function (elem) {
    popout.articleIdVal = JSON.parse($(elem).attr("data-article-id"));
};

popout.identifyArticleType = function () {
    switch (popout.articleTypeVal) {
        case 0:
            popout.articleType = "PreviousWinners";
            break;
        case 1:
            popout.articleType = "HOF";
            break;
        case 2:
            popout.articleType = "QuickThanks";
            break;
        case 3:
            popout.articleType = "Awards";
            break;
        case 4:
            popout.articleType = "Card";
            break;
        case 5:
            popout.articleType = "Info";
            break;
        case 6:
            popout.articleType = "GalleryImage";
            break;
        //default:
        //    popout.articleType = "Info";
        //    break;
    }
};

popout.checkPopupExists = function () {
    if (!$(popout.overlayPanelContainer).length > 0) {
        popout.createStructuralMarkup();
        popout.reInit();
        popout.reBind();
    }
};

popout.reInit = function () {
    popout.overlayPanelContainer = $("." + popout.overlayPanelContainerClass);
    popout.overlayPanelWrap = popout.overlayPanelContainer.find("." + popout.overlayPanelWrapClass);
    popout.overlayPanelInner = popout.overlayPanelContainer.find("." + popout.overlayPanelInnerClass);

    popout.overlayCTAClose = $("." + popout.overlayCTACloseClass);
    popout.overlayCTACloseIcon = $("." + popout.overlayCTACloseIconClass);
};

popout.reBind = function () {
    popout.clickCloseBtn();
    popout.clickCloseIcon();
    popout.clickOverlayShadow();
};

popout.assembleArticle = function (elem) {

    switch (popout.articleType) {
        case "Awards":
            popout.getAwardsRequest(elem);
            break;
        case "HOF":
            popout.getHOFRequest(elem);
            break;
        case "QuickThanks":
            popout.getStatementRequest(elem);
            break;
        case "Card":
            popout.getCardRequest(elem);
            break;
        case "PreviousWinners":
            popout.getPrevWinnersRequest(elem);
            break;
        case "GalleryImage":
            popout.getGalleryImageRequest(elem);
            break;
        default:
            popout.getDefaultRequest(elem);
            break;
    }
};

popout.identifyArrows = function (button, firstClass, secondClass) {

    var firstElem = $("." + firstClass),
        firstElemLength = firstElem.length;

    for (var i = 0; i < firstElem.length; i++) {
        var buttonIndex = button.parents("." + firstClass).index(),
            nextIndex,
            prevIndex;

        var articleInitOuterElem = $(firstElem[i]);


        var secondElem = "",
            secondElemLength = "",
            secondButtonIndex = "",
            secondArticleInitOuterElem = "",
            j = 0;


        if (articleInitOuterElem.index() === buttonIndex) {


            if (articleInitOuterElem.index() == 0) {

                if (secondClass) {




                    secondElem = articleInitOuterElem.find("." + secondClass),
                        secondElemLength = secondElem.length;

                    for (j = 0; j < secondElem.length; j++) {

                        secondButtonIndex = button.parents("." + secondClass).index();
                        //console.log("button index is: " + secondButtonIndex);

                        secondArticleInitOuterElem = $(secondElem[j]);
                        //console.log("row index is: " + secondArticleInitOuterElem.index());

                        if (secondArticleInitOuterElem.index() === secondButtonIndex) {

                            if (secondArticleInitOuterElem.index() == 0) {
                                popout.arrowType = "rightArrow";
                                nextIndex = j + 1;
                                popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                return;
                            } else if (secondArticleInitOuterElem.index() == secondElemLength - 1) {
                                popout.arrowType = "leftArrow";
                                prevIndex = j - 1;
                                popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                return;
                            } else {
                                popout.arrowType = "both";
                                nextIndex = j + 1;
                                prevIndex = j - 1;
                                popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                return;
                            }
                        }
                    }
                } else {
                    popout.arrowType = "rightArrow";
                    nextIndex = i + 1;
                    popout.elementNext = $(firstElem[nextIndex]).find(".c-button--overlay-open");
                    return;
                }

            } else if (articleInitOuterElem.index() == firstElemLength - 1) {

                if (secondClass) {




                    secondElem = articleInitOuterElem.find("." + secondClass),
                        secondElemLength = secondElem.length;

                    for (j = 0; j < secondElem.length; j++) {

                        secondButtonIndex = button.parents("." + secondClass).index();
                        //console.log("button index is: " + secondButtonIndex);

                        secondArticleInitOuterElem = $(secondElem[j]);
                        //console.log("row index is: " + secondArticleInitOuterElem.index());

                        if (secondArticleInitOuterElem.length !== 1) {

                            if (secondArticleInitOuterElem.index() === secondButtonIndex) {
                                if (secondArticleInitOuterElem.index() == 0) {
                                    popout.arrowType = "rightArrow";
                                    nextIndex = j + 1;
                                    popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                    return;
                                } else if (secondArticleInitOuterElem.index() == secondElemLength - 1) {
                                    popout.arrowType = "leftArrow";
                                    prevIndex = j - 1;
                                    popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                    return;
                                } else {
                                    popout.arrowType = "both";
                                    nextIndex = j + 1;
                                    prevIndex = j - 1;
                                    popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                    popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                    return;
                                }
                            }
                        }
                    }
                } else {
                    popout.arrowType = "leftArrow";
                    prevIndex = i - 1;
                    popout.elementPrev = $(firstElem[prevIndex]).find(".c-button--overlay-open");
                    return;
                }
            } else {

                if (secondClass) {




                    secondElem = articleInitOuterElem.find("." + secondClass),
                        secondElemLength = secondElem.length;

                    for (j = 0; j < secondElem.length; j++) {

                        secondButtonIndex = button.parents("." + secondClass).index();
                        console.log("button index is: " + secondButtonIndex);

                        secondArticleInitOuterElem = $(secondElem[j]);
                        console.log("row index is: " + secondArticleInitOuterElem.index());

                        if (secondArticleInitOuterElem.length !== 1) {

                            if (secondArticleInitOuterElem.index() === secondButtonIndex) {
                                if (secondArticleInitOuterElem.index() == 0) {
                                    popout.arrowType = "rightArrow";
                                    nextIndex = j + 1;
                                    popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                    return;
                                } else if (secondArticleInitOuterElem.index() == secondElemLength - 1) {
                                    popout.arrowType = "leftArrow";
                                    prevIndex = j - 1;
                                    popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                    return;
                                } else {
                                    popout.arrowType = "both";
                                    nextIndex = j + 1;
                                    prevIndex = j - 1;
                                    popout.elementNext = $(secondElem[nextIndex]).find(".c-button--overlay-open");
                                    popout.elementPrev = $(secondElem[prevIndex]).find(".c-button--overlay-open");
                                    return;
                                }
                            }
                        }
                    }
                } else {
                    popout.arrowType = "both";
                    nextIndex = i + 1;
                    prevIndex = i - 1;
                    popout.elementNext = $(firstElem[nextIndex]).find(".c-button--overlay-open");
                    popout.elementPrev = $(firstElem[prevIndex]).find(".c-button--overlay-open");
                    return;
                }
            }
        }
    }
};

popout.changeBackButton = function () {
    popout.overlayCTAClose.text("Back to the Wall");
};

popout.checkNeighbouringArticles = function () {
    switch (popout.arrowType) {
        case "leftArrow":
            popout.appendLeftArrow();
            break;
        case "rightArrow":
            popout.appendRightArrow();
            break;
        case "both":
            popout.appendLeftArrow();
            popout.appendRightArrow();
            break;
        default:
            break;
    }
};

popout.appendLeftArrow = function () {
    var html = "<button class=\"c-overlay-panel__arrow-btn c-overlay-panel__arrow-btn--left\" type=\"\"><svg class=\"c-icon c-icon--left-arrow-popout\"><use xlink:href=\"/images/icons/icon-sprite.svg#icon-arrow-left-carousel\"></use></svg></button>";

    popout.overlayPanelWrap.append(html);

    popout.leftArrow = $(".c-overlay-panel__arrow-btn--left");
    popout.bindLeftArrow();
};

popout.appendRightArrow = function () {
    var html = "<button class=\"c-overlay-panel__arrow-btn c-overlay-panel__arrow-btn--right\" type=\"\"><svg class=\"c-icon c-icon--left-arrow-popout\"><use xlink:href=\"/images/icons/icon-sprite.svg#icon-arrow-right-carousel\"></use></svg></button>";

    popout.overlayPanelWrap.append(html);
    popout.rightArrow = $(".c-overlay-panel__arrow-btn--right");
    popout.bindRightArrow();
};

popout.bindLeftArrow = function () {
    popout.leftArrow.off("click").on("click", function () {
        popout.overlayPanelContainer.remove();
        popout.overlayPanelContainer = "";
        popout.ajaxSetup(popout.elementPrev);
    });
};

popout.bindRightArrow = function () {
    popout.rightArrow.off("click").on("click", function () {
        popout.overlayPanelContainer.remove();
        popout.overlayPanelContainer = "";
        popout.ajaxSetup(popout.elementNext);
    });
};

//popout.applySolidThemeColours = function (button) {
//    var elementParent = button.parents(".c-card");

//    if (elementParent.hasClass("c-card--pink")) {
//        popout.overlayPanelContainer.find(".c-overlay-panel__title").addClass("c-overlay-panel__title--pink");
//    } else if (elementParent.hasClass("c-card--dark-blue")) {
//        popout.overlayPanelContainer.find(".c-overlay-panel__title").addClass("c-overlay-panel__title--dark-blue");
//    } else if (elementParent.hasClass("c-card--deep-orange")) {
//        popout.overlayPanelContainer.find(".c-overlay-panel__title").addClass("c-overlay-panel__title--deep-orange");
//    } else if (elementParent.hasClass("c-card--green")) {
//        popout.overlayPanelContainer.find(".c-overlay-panel__title").addClass("c-overlay-panel__title--green");
//    } else {
//        popout.overlayPanelContainer.find(".c-overlay-panel__title").addClass("c-overlay-panel__title--blue");
//    }
//};

popout.applyGradientThemeColours = function (button) {
    var elementParent = button.parents(".c-card");

    if (elementParent.hasClass("c-card--blue-gradient")) {
        popout.overlayPanelContainer.find(".c-statement").addClass("c-statement--blue-gradient");
    } else if (elementParent.hasClass("c-card--orange-gradient")) {
        popout.overlayPanelContainer.find(".c-statement").addClass("c-statement--orange-gradient");
    } else if (elementParent.hasClass("c-card--green-gradient")) {
        popout.overlayPanelContainer.find(".c-statement").addClass("c-statement--green-gradient");
    } else {
        popout.overlayPanelContainer.find(".c-statement").addClass("c-statement--blue-gradient");
    }
};

popout.setSmallWrapClass = function () {
    if (!popout.overlayPanelWrap.hasClass(popout.overlayPanelWrapSmallClass)) popout.overlayPanelWrap.addClass(popout.overlayPanelWrapSmallClass);
};

popout.removeSmallWrapClass = function () {
    if (popout.overlayPanelWrap.hasClass(popout.overlayPanelWrapSmallClass)) popout.overlayPanelWrap.removeClass(popout.overlayPanelWrapSmallClass);
};

popout.createStructuralMarkup = function () {
    var html = '<div class="c-overlay-panel"><div class="c-overlay-panel__wrap"><div class="c-overlay-panel__icon"><svg class="c-icon c-icon--popout-close"><use xlink:href="/images/icons/icon-sprite.svg#icon-close"></use></svg></div><div class="c-overlay-panel__inner c-overlay-panel__inner--loading"></div><div class="c-overlay-panel__cta"><button class="c-button c-button--overlay-close" type="button">Back to the list</button></div></div></div>';

    $("body").append(html);
};

popout.createAwardsMarkup = function (element) {
    var html = '<div class="c-overlay-panel__banner"><div class="c-overlay-panel__banner-image"></div></div><div class="o-strip o-strip--centered o-strip--curved-top"><div class="c-overlay-panel__content"><div class="c-date-box c-date-box--blue"><div class="c-date-box__wrap"><div class="c-date-box__inner"><p></p></div></div></div><div class="c-intro-section"><div class="c-overlay-panel__title c-overlay-panel__title--blue"><div class="c-content"><h2></h2><p class="c-intro-section__text c-intro-section__text--left"></p></div></div></div></div></div><div class="o-strip o-strip--centered o-strip--dark-blue"><div class="o-wrap o-wrap--small"><div class="o-gutter"><div class="o-content-wrap o-content-wrap--center o-content-wrap--smaller"><div class="c-video-wrapper"><div class="c-video-wrapper__video"></div><p class="c-video-wrapper__text"></p></div></div></div></div></div><div class="c-overlay-panel__content"><div class="c-overlay-panel__text"><div class="c-content"></div></div></div><div class="o-strip o-strip--blue"><div class="o-wrap o-wrap--small"><div class="c-overlay-panel__content"><div class="c-content"><div class="c-content__header"><div class="c-content__title c-content__title--light"><h2 class="c-award-title"></h2><p class="c-award-detail"></p></div></div></div></div></div></div>';

    element.append(html);
};

popout.createHOFMarkup = function (element) {
    var html = '<div class="c-overlay-panel__banner"><div class="c-overlay-panel__banner-image"></div></div><div class="o-strip o-strip--centered o-strip--curved-top"><div class="c-overlay-panel__content c-overlay-panel__content--padding-bottom-0"><div class="c-date-box c-date-box--blue"><div class="c-date-box__wrap"><div class="c-date-box__inner"><p></p></div></div></div><div class="c-intro-section"><div class="c-overlay-panel__title c-overlay-panel__title--blue"><div class="c-content"><h2></h2><p class="c-intro-section__text c-intro-section__text--left"></p></div></div></div></div></div><div class="c-overlay-panel__content c-overlay-panel__content--padding-top-0"><div class="c-overlay-panel__text"><div class="c-content"></div></div></div>';

    element.append(html);
};

popout.createStatementMarkup = function (element) {
    var html = '<div class="c-overlay-panel__banner"><div class="c-statement"><div class="c-statement__wrap"><div class="c-statement__inner"><div class="c-statement__addressed-to"></div><div class="c-statement__quote"></div><p>from</p><div class="c-statement__location"></div></div></div></div></div><div class="c-overlay-panel__content"><div class="c-date-box c-date-box--no-padding c-date-box--bottom-30"><div class="c-date-box__inner"><p></p></div></div><div class="c-overlay-panel__text"><div class="c-content"></div></div></div>';

    element.append(html);
};

popout.createCardMarkup = function (element) {
    var html = '<div class="c-preview-card-container"><div class="c-preview-card"><div class="c-preview-card__box"><div class="c-preview-card__content"><div class="c-preview-card__content-inner"><div class="c-content"><div class="c-preview-card__logo"><svg class="c-icon c-icon--logo"><use xlink: href="/images/icons/icon-sprite.svg#coop-logo"></use></svg></div><h1 class="c-preview-card__title"></h1><h2 class="c-preview-card__to-text"></h2><p class="c-preview-card__text"></p><h2 class="c-preview-card__from-text"></h2></div></div></div><div class="c-preview-card__img"></div></div></div><div class="c-date-box"><div class="c-date-box__wrap"><div class="c-date-box__inner"><p></p></div></div></div></div>';

    element.append(html);
};

popout.createGalleryImageMarkup = function (element) {
    var html = '<div class="c-overlay-panel__content"><div class="c-overlay-panel__title"><div class="c-content"><div class="c-content__title"><h2 class="gallery-title"></h2></div></div></div><div class="gallery-image"></div><br /><div class="c-overlay-panel__text gallery-text"><div class="c-content"></div></div></div>';

    element.append(html);
};

popout.createDefaultMarkup = function (element) {
    var html = '<div class="c-overlay-panel__content"><div class="c-overlay-panel__title"><div class="c-content"><div class="c-content__title"><h2></h2></div></div></div><div class="c-overlay-panel__text"><div class="c-content"></div></div></div>';

    element.append(html);
};

popout.initAwards = function () {
    popout.bannerImage = popout.overlayPanelContainer.find("." + popout.bannerImageClass);
    popout.date = popout.overlayPanelContainer.find(".c-date-box p");
    popout.title = popout.overlayPanelContainer.find("." + popout.titleClass + " h2");
    popout.summary = popout.overlayPanelContainer.find(".c-intro-section__text");
    popout.video = popout.overlayPanelContainer.find(".c-video-wrapper__video");
    popout.videoText = popout.overlayPanelContainer.find(".c-video-wrapper__text");
    popout.text = popout.overlayPanelContainer.find("." + popout.textClass);
    popout.awardTitle = popout.overlayPanelContainer.find(".c-award-title");
    popout.awardDetail = popout.overlayPanelContainer.find(".c-award-detail");
};

popout.initHOF = function () {
    popout.bannerImage = popout.overlayPanelContainer.find("." + popout.bannerImageClass);
    popout.date = popout.overlayPanelContainer.find(".c-date-box p");
    popout.title = popout.overlayPanelContainer.find("." + popout.titleClass + " h2");
    popout.summary = popout.overlayPanelContainer.find(".c-intro-section__text");
    popout.text = popout.overlayPanelContainer.find("." + popout.textClass);
};

popout.initStatement = function () {
    popout.toName = popout.overlayPanelContainer.find(".c-statement__addressed-to");
    popout.title = popout.overlayPanelContainer.find(".c-statement__quote");
    popout.fromName = popout.overlayPanelContainer.find(".c-statement__location");
    popout.date = popout.overlayPanelContainer.find(".c-date-box p");
    popout.text = popout.overlayPanelContainer.find("." + popout.textClass);
};

popout.initCard = function () {
    popout.bannerImage = popout.overlayPanelContainer.find(".c-preview-card__img");
    popout.title = popout.overlayPanelContainer.find(".c-preview-card__title");
    popout.toText = popout.overlayPanelContainer.find(".c-preview-card__to-text");
    //popout.normalText = popout.overlayPanelContainer.find(".c-preview-card__text");
    popout.fromText = popout.overlayPanelContainer.find(".c-preview-card__from-text");
    popout.date = popout.overlayPanelContainer.find(".c-date-box p");
};

popout.initGallery = function () {
    popout.Title = popout.overlayPanelContainer.find(".gallery-title");
    popout.Image_PICT = popout.overlayPanelContainer.find(".gallery-image");
    popout.Summary = popout.overlayPanelContainer.find(".gallery-text");
}

popout.initDefault = function () {
    popout.title = popout.overlayPanelContainer.find("." + popout.titleClass + " h2");
    popout.text = popout.overlayPanelContainer.find("." + popout.textClass);
};

popout.getAwardsRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/wall-hof-award-data.ashx?fdId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createAwardsMarkup($(popout.overlayPanelInner));
            popout.initAwards();
            popout.removeSmallWrapClass();
            //popout.applySolidThemeColours(button);
            parentArticleClass = "c-tile";
            innerClassArticle = "c-card";
            popout.changeBackButton();
            if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass);
            popout.applyStoryData(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
            if (popout.buttonClicked) popout.checkNeighbouringArticles();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.getHOFRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/wall-hof-data.ashx?fdId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createHOFMarkup($(popout.overlayPanelInner));
            popout.initHOF();
            popout.removeSmallWrapClass();
            parentArticleClass = "c-tile";
            innerClassArticle = "c-card";
            popout.changeBackButton();
            if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass);
            popout.applyHOFData(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
            if (popout.buttonClicked) popout.checkNeighbouringArticles();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.getStatementRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/wall-quick-thanks-data.ashx?fdId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createStatementMarkup($(popout.overlayPanelInner));
            popout.initStatement();
            popout.removeSmallWrapClass();
            if (popout.buttonClicked) popout.applyGradientThemeColours(button);
            parentArticleClass = "c-tile";
            innerClassArticle = "c-card";
            popout.changeBackButton();
            if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass);
            popout.applyStatementData(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
            if (popout.buttonClicked) popout.checkNeighbouringArticles();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.getCardRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/wall-card-data.ashx?fdId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createCardMarkup($(popout.overlayPanelInner));
            popout.initCard();
            popout.setSmallWrapClass();
            parentArticleClass = "c-tile";
            innerClassArticle = "c-card";
            popout.changeBackButton();
            if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass);
            popout.applyCardData(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
            if (popout.buttonClicked) popout.checkNeighbouringArticles();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.getGalleryImageRequest = function (button) {

    if (counter == 0) {

        var data = popout.articleIdVal,
            pathname = "/ashx/gallery-image-data.ashx?fdId=" + data,
            targetUrl = pathname;

        $.ajax({
            type: "GET",
            url: targetUrl,
            contentType: "application/json; charset=utf-8",
            success: function (r) {
                var parentArticleClass = "",
                    innerClassArticle = "";

                popout.checkPopupExists();
                popout.createGalleryImageMarkup($(popout.overlayPanelInner));
                popout.initGallery();
                popout.removeSmallWrapClass();
                //if (popout.buttonClicked) popout.applyGradientThemeColours(button);
                parentArticleClass = "c-tile";
                innerClassArticle = "c-card";
                popout.changeBackButton();
                //if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass);
                //if (popout.overlayCTAClose.text == "Back to the gallery") { break; }
                popout.applyGalleryData(JSON.parse(r));
                popout.appendBodyScrollPos();
                popout.addOverlayShadow();
                popout.overlayCTAClose.text("Back to the gallery");
                if (popout.buttonClicked) popout.checkNeighbouringArticles();
            },
            error: function (r) {
                console.error(r);
            }
        });
    }
    counter++;
};

popout.getPrevWinnersRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/previous-winners.ashx?fdId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createDefaultMarkup($(popout.overlayPanelInner));
            popout.initDefault();
            popout.removeSmallWrapClass();
            parentArticleClass = "c-themed-list__row";
            innerClassArticle = "c-themed-list__list-item";
            popout.applyDefaultWinner(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.getDefaultRequest = function (button) {
    var data = popout.articleIdVal,
        pathname = "/ashx/nominees.ashx?fdNomineeId=" + data,
        targetUrl = pathname;

    $.ajax({
        type: "GET",
        url: targetUrl,
        contentType: "application/json; charset=utf-8",
        success: function (r) {
            var parentArticleClass = "",
                innerClassArticle = "";

            popout.checkPopupExists();
            popout.createDefaultMarkup($(popout.overlayPanelInner));
            popout.initDefault();
            popout.removeSmallWrapClass();
            parentArticleClass = "c-themed-list__row";
            innerClassArticle = "c-themed-list__list-item";
            //if (popout.buttonClicked) popout.identifyArrows(button, parentArticleClass, innerClassArticle);
            popout.applyDefaultData(JSON.parse(r));
            popout.appendBodyScrollPos();
            popout.addOverlayShadow();
            if (popout.buttonClicked) popout.checkNeighbouringArticles();
        },
        error: function (r) {
            console.error(r);
        }
    });
};

popout.applyStoryData = function (data) {
    var bannerImg = data.Image_PICT,
        bannerVerticalPos = data.VerticalImagePosition,
        bannerHorizontalPos = data.HorizontalImagePosition,
        date = data.Date_DT,
        title = data.Title,
        summary = data.Summary,
        video = "<iframe src='" + data.AwardYouTubeURL + "' frameborder='0'></iframe>",
        videoText = data.YoutubeNote,
        text = data.Detail,
        awardTitle = data.AwardTitle,
        awardDetail = data.AwardDetail;

    popout.bannerImage.css({
        "background-image": "url(" + bannerImg + ")",
        "background-position": bannerHorizontalPos + " " + bannerVerticalPos
    });

    popout.date.append(date);
    popout.title.append(title);
    popout.summary.append(summary);

    if (data.AwardYouTubeURL) {
        popout.video.append(video);
        popout.videoText.append(videoText);
    } else {
        popout.title.parents(".c-overlay-panel__content").addClass("c-overlay-panel__content--padding-bottom-0");
        popout.text.parents(".c-overlay-panel__content").addClass("c-overlay-panel__content--padding-top-0");
        popout.video.parents(".o-strip--dark-blue").addClass("t-visuallyhidden");
    }

    popout.text.append(text);

    if (awardTitle) {
        popout.awardTitle.append(awardTitle);
        popout.awardDetail.append(awardDetail);
    } else {
        popout.awardTitle.parents(".o-strip--blue").addClass("t-visuallyhidden");
    }
};

popout.applyHOFData = function (data) {
    var bannerImg = data.Image_PICT,
        bannerVerticalPos = data.VerticalImagePosition,
        bannerHorizontalPos = data.HorizontalImagePosition,
        date = data.Date_DT,
        title = data.Title,
        summary = data.Summary,
        text = data.Detail;

    popout.bannerImage.css({
        "background-image": "url(" + bannerImg + ")",
        "background-position": bannerHorizontalPos + " " + bannerVerticalPos
    });
    popout.date.append(date);
    popout.title.append(title);
    popout.summary.append(summary);
    popout.text.append(text);
};

popout.applyStatementData = function (data) {
    var toName = data.ToName,
        title = data.Title,
        fromName = data.FromName,
        date = data.Date,
        text = data.Detail;

    popout.toName.append(toName);
    popout.title.append(title);
    popout.fromName.append(fromName);
    popout.date.append(date);
    popout.text.append(text);
};

popout.applyGalleryData = function (data) {
    console.log(data);

    popout.Title.append(data.Title);
    popout.Image_PICT.append(data.Image_PICT);
    popout.Summary.append(data.Summary);
};

popout.applyCardData = function (data) {
    var bannerImg = data.ImageURL,
        title = data.CardTitle,
        toText = data.ToName,
        //normalText = data.Content,
        fromText = data.FromName,
        date = data.CardDate.split(' ')[0];

    popout.bannerImage.css({ "background-image": "url(" + bannerImg + ")" });
    popout.title.append(title);
    popout.toText.append(toText);
    //popout.normalText.append(normalText);
    popout.fromText.append(fromText);
    popout.date.append(date);
};

popout.applyDefaultData = function (data) {
    var title = data.Name,
        text = data.Bio;

    popout.title.append(title);
    popout.text.append(text);
};

popout.applyDefaultWinner = function (data) {
    var title = data.Name,
        text = data.WinnerFullText;

    popout.title.append(title);
    popout.text.append(text);
};

popout.addOverlayShadow = function () {
    popout.outerPage.addClass(popout.outerPageFixedClass);

    setTimeout(function () {
        popout.overlayPanelContainer.addClass(popout.overlayPanelVisibleClass);
    }, 1);
};

popout.removeOverlayShadow = function () {
    $(popout.overlayPanelContainer).removeClass(popout.overlayPanelVisibleClass);
};

popout.getScrollPos = function () {
    popout.scrollPos = window.pageYOffset;
};

popout.setScrollPos = function () {
    window.scrollTo(0, popout.scrollPos);
};

popout.appendBodyScrollPos = function () {
    $(".o-main-content").css({
        "margin-top": -popout.scrollPos + "px"
    });
};

popout.removeBodyScrollPos = function () {
    $(".o-main-content").css({
        "margin-top": ""
    });
};

popout.ajaxSetup = function (elem) {
    //document.getElementById("card-gallery").disabled = true;
    if (counter == 0) {
        counter++;
        popout.setup();
        popout.buttonClicked = true;
        popout.getButtonArticleType(elem);
        popout.getButtonArticleId(elem);
        popout.openPopup(elem);
        //document.getElementById("card-gallery").disabled = false;
        setTimeout(counter = 0, 5000);
    }
};

popout.setup = function () {
    popout.outerPageClass = "o-outer-page";
    popout.outerPageFixedClass = "o-outer-page--fixed";

    popout.overlayCTAOpenClass = "c-button--overlay-open";
    popout.overlayCTACloseClass = "c-button--overlay-close";
    popout.overlayCTACloseIconClass = "c-overlay-panel__icon";
    popout.overlayPanelContainerClass = "c-overlay-panel";
    popout.overlayPanelVisibleClass = "c-overlay-panel--visible";
    popout.overlayShadowContainerClass = "o-content";

    popout.overlayPanelWrapClass = "c-overlay-panel__wrap";
    popout.overlayPanelWrapSmallClass = "c-overlay-panel__wrap--small";
    popout.overlayPanelInnerClass = "c-overlay-panel__inner";
    popout.overlayPanelLoadingClass = "c-overlay-panel__inner--loading";
    popout.bannerImageClass = "c-overlay-panel__banner-image";
    popout.dateClass = "c-overlay-panel__date";
    popout.titleClass = "c-overlay-panel__title";
    popout.textClass = "c-overlay-panel__text";

    popout.buttonClicked = false;

    popout.outerPage = $("." + popout.outerPageClass);

    popout.overlayShadowContainer = $("." + popout.overlayShadowContainerClass);
};

popout.init = function () {

    popout.setup();
    popout.onLoad();

    popout.overlayCTAOpen = $("." + popout.overlayCTAOpenClass);

    if (popout.overlayCTAOpen.length > 0) {
        popout.bind();
    }
};

$(function () {
    popout.init();
});