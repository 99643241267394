/*
    accordions
    Version:1.1
    Author:Rory Saxby & Jake Upshall
    Dependencies: jQuery

    EXAMPLE:

    *** Initialise ***

    var generalAccordions = new logicjs.accordions({
        container: ".page-accordions",
        accordionsClass: ".general-accordion",
        accordionCtrlTarget: ".toggle-ctrl",
        hiddenClass: "hidden",
        accordionView: ".accordion-view",
        loadingClass: "loading"
    });

    *** Markup ***
    <div class="page-accordions">
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 1</button>
            <div class="accordion-view">
                <!-- content here --> 
            </div>
        </div>
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 2</button>
            <div class="accordion-view">
                <!-- content here -->
            </div>
        </div>
        <div class="general-accordion">
            <button class="toggle-ctrl">Link 3</button>
            <div class="accordion-view">
                <!-- content here -->
            </div>
        </div>
    </div>

 */

logicjs.accordions = function (opts) {
    this.container = $(opts.container);
    this.accordionsClass = opts.accordionsClass;
    this.accordions = this.container.find(this.accordionsClass);
    this.accordionCtrlTarget = opts.accordionCtrlTarget;
    this.accordionClickCallback = opts.accordionClickCallback || false;
    this.hiddenClass = opts.hiddenClass || "inactive";
    this.loadActiveClass = opts.loadActiveClass || "initially-opened";
    this.onLoadActiveCheckCallback = opts.onLoadActiveCheckCallback || false;
    this.accordionView = opts.accordionView;
    this.parentAccordionView = opts.parentAccordionView || null;
    this.loadingClass = opts.loadingClass || "loading";
    this.storeOpenCookie = opts.storeOpenCookie || false; // {name:"", value:"", expires} || false;
    this.toggleAccordions = opts.toggleAccordions || false;
    this.toggleCallback = opts.toggleCallback || false;
    this.setAccordionHeights = opts.setAccordionHeights != null ? opts.setAccordionHeights : true;
    this.disableAccordionClick = opts.disableAccordionClick || false;
    this.loadHidden = opts.loadAccordionsHidden != null ? opts.loadAccordionsHidden : true;

    this.setupAccordions();
};

logicjs.accordions.prototype.bindAccordions = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        var _ = this;
        $(this.accordions[i]).find(this.accordionCtrlTarget).off('click').on('click', function (e) {
            e.preventDefault();
            if (!_.disableAccordionClick) _.setActiveAccordion($(this).parents(_.accordionsClass));
        });
    }
};

logicjs.accordions.prototype.setActiveAccordion = function (selected, disableCookieUpdate) {
    if (selected.hasClass(this.hiddenClass)) {
        if (this.toggleAccordions) this.hideAll();
        selected.removeClass(this.hiddenClass);
        this.openSelectedView(selected);
    } else {
        selected.addClass(this.hiddenClass);
        this.hideSelectedView(selected);
    }

    if (this.storeOpenCookie && !disableCookieUpdate) {
        this.updateStoredCookie(selected);
        this.updateCookie();
    }

    if (this.toggleCallback) this.toggleCallback();
};

logicjs.accordions.prototype.updateCookie = function () {
    var value = JSON.stringify(this.storedCookie);
    logicjs.setCookie(this.storeOpenCookie.name, value, 7);
};

logicjs.accordions.prototype.updateStoredCookie = function (accordion) {
    var name = accordion.find(this.accordionCtrlTarget).attr(this.storeOpenCookie.attr);
    if (this.storedCookie.length > 0) {
        for (var i = 0; i < this.storedCookie.length; i++) {
            if (this.storedCookie[i] === name) {
                this.storedCookie.splice(i, 1);
                return;
            }
        }
        this.storedCookie.push(name);
    } else {
        this.storedCookie.push(name);
    }
};

logicjs.accordions.prototype.openSelectedView = function (selected) {
    if (this.setAccordionHeights) {
        var view = selected.find(this.accordionView),
            height = view[0].scrollHeight * 3;

        view[0].style.maxHeight = height + "px";
        this.setParentView(view, height);
    }
};

logicjs.accordions.prototype.setParentView = function (view, height) {
    if (this.parentAccordionView !== null) {
        var parentView = $(view).parents(this.parentAccordionView);
        parentView[0].style.maxHeight = parentView[0].scrollHeight + height + "px";
    }
};

logicjs.accordions.prototype.hideAll = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        var selected = $(this.accordions[i]);

        if (!selected.hasClass(this.loadActiveClass)) {
            this.hideSelectedView(selected);
        } else {
            selected.removeClass(this.loadActiveClass);
        }

    }
};

logicjs.accordions.prototype.hideSelectedView = function (selected) {
    selected.addClass(this.hiddenClass);
    if (this.setAccordionHeights) selected.find(this.accordionView)[0].style.maxHeight = 0;
};

logicjs.accordions.prototype.removeAllSetHeights = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        var view = $(this.accordions[i]).find(this.accordionView);
        view.attr('style', '');
    }
};

logicjs.accordions.prototype.unset = function () {
    for (var i = 0; i < this.accordions.length; i++) {
        $(this.accordions[i]).find(this.accordionCtrlTarget).unbind('click');
        $(this.accordions[i]).removeClass(this.hiddenClass);
        $(this.accordions[i]).find(this.accordionView)[0].style = "";
    }
};

logicjs.accordions.prototype.getStoredCookie = function () {
    var value = logicjs.getCookie(this.storeOpenCookie.name);
    this.storedCookie = [];
    if (value !== "") this.storedCookie = JSON.parse(value);
};

logicjs.accordions.prototype.setupAccordions = function () {
    if (this.storeOpenCookie) this.getStoredCookie();
    this.container.addClass(this.loadingClass);
    this.bindAccordions();
    if (this.loadHidden) this.hideAll();
    this.container.removeClass(this.loadingClass);
};

/* Functions for external use */

logicjs.accordions.prototype.changeOpt = function (opt, value) {
    this[opt] = value;

    switch (opt) {
        case "toggleAccordions":
            if (value) {
                this.hideAll();
            }
            break;
        case "setAccordionHeights":
            if (value) {
                this.hideAll();
            } else {
                this.removeAllSetHeights();
            }
            break;
    }
};