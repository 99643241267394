/*
    inputsTyped
    Version:1.2
    Author:Rory Saxby
    Dependencies: jQuery
    
    SUMMARY:

    Applies a keypress and value load check to all targeted inputs, applying classes for styling

    EXAMPLE:

    *** Initialise ***

    var exampleInputs = new logicjs.inputsTyped({
        parentTarget: '.example-target', REQUIRED
        inputStringArray: 'body input[type="text"], body input[type="email"]',
        activeClass: 'value-added'
    });

 */

logicjs.inputsTyped = function (opts) {
    this.opts = {};
    this.opts.inputStringArray = opts.inputStringArray || 'body input[type="text"], body input[type="email"], body input[type="number"], body input[type="password"], body textarea';
    this.opts.selectStringArray = opts.selectStringArray || 'body select';
    this.opts.activeClass = opts.activeClass || 'value-added';
    this.opts.focusClass = opts.focusClass || 'input-focused';
    this.opts.selectFocusClass = opts.selectFocusClass || 'select-focused';
    this.opts.parentTarget = opts.parentTarget || false;
    this.opts.autoFillCheckDelay = opts.autoFillCheckDelay || 150;

    if (!opts.parentTarget) {
        console.log('REQUIRED: please add select a parentTarget for focus check');
        return;
    };

    // jQuery opts

    this.opts.inputsArray = $(this.opts.inputStringArray);
    this.opts.selectArray = $(this.opts.selectStringArray);

    this.opts.prevInputs = [];

    this.prefix = logicjs.browserKit();
    this.selectInputs();
    this.selectSelects();
    this.autoFillCheck();
};

logicjs.inputsTyped.prototype.selectInputs = function () {
    for (var i = 0; i < this.opts.inputsArray.length; i++) {
        this.bindKeycheck(this.opts.inputsArray[i]);
        this.bindInputClickCheck(this.opts.inputsArray[i]);
        this.hasValueCheck(this.opts.inputsArray[i]);
    };
};

logicjs.inputsTyped.prototype.selectSelects = function () {
    for (var i = 0; i < this.opts.selectArray.length; i++) {
        this.bindSelectCheck(this.opts.selectArray[i]);
        this.hasValueCheck(this.opts.selectArray[i]);
    };
};

logicjs.inputsTyped.prototype.hasValueCheck = function (input) {
    var _input = $(input);
    if (input.value !== "") {
        _input.parents(this.opts.parentTarget).addClass(this.opts.activeClass);
    };
};

logicjs.inputsTyped.prototype.autoFillCheck = function () {
    var _ = this;
    setTimeout(function(){
        for (var i = 0; i < _.opts.inputsArray.length; i++) {
            var input = $(_.opts.inputsArray[i]);

            if(_.prefix.css === "-moz-" || _.prefix.css === "-ms-"){
                return;
            };

            if (input.is(":" + _.prefix.css + "autofill")) {
                input.parents(_.opts.parentTarget).addClass(_.opts.activeClass);
            };
        };
    },_.opts.autoFillCheckDelay);
};

logicjs.inputsTyped.prototype.bindKeycheck = function (input) {
    var _ = this;

    input.addEventListener('keyup', function (e) {
        _.inputValueCheck(this);
    });

    input.addEventListener('change', function (e) {
        _.inputValueCheck(this);
    });
};

logicjs.inputsTyped.prototype.inputValueCheck = function (input) {
    if (input.value.length > 0) {
        $(input).parents(this.opts.parentTarget).addClass(this.opts.activeClass);
    };
    if (input.value.length <= 0) {
        $(input).parents(this.opts.parentTarget).removeClass(this.opts.activeClass);
    };
};

logicjs.inputsTyped.prototype.bindInputClickCheck = function (input) {
    var _ = this;

    input.addEventListener('click', function (e) {
        e.stopPropagation();
        _.removeInputsFocus();
        _.checkPrevInputs();
        _.opts.prevInputs.push($(this));
        var selectedInput = $(this);
        selectedInput.parents(_.opts.parentTarget).addClass(_.opts.focusClass);
        document.body.addEventListener('click', function () {
            _.inputValueCheck(selectedInput[0]);
            selectedInput.parents(_.opts.parentTarget).removeClass(_.opts.focusClass);
        });
    });
};

logicjs.inputsTyped.prototype.checkPrevInputs = function () {
    for (var i = 0; i < this.opts.prevInputs.length; i++) {
        this.inputValueCheck(this.opts.prevInputs[i][0]);
    };
    this.opts.prevInputs = [];
};

logicjs.inputsTyped.prototype.removeInputsFocus = function () {
    for (var i = 0; i < this.opts.inputsArray.length; i++) {
        $(this.opts.inputsArray[i]).parents(this.opts.parentTarget).removeClass(this.opts.focusClass);
    };
};

logicjs.inputsTyped.prototype.bindSelectCheck = function (selectbox) {
    var _ = this;

    selectbox.addEventListener('click', function (e) {
        e.stopPropagation();
        var selectedInput = $(this);
        selectedInput.parents(_.opts.parentTarget).addClass(_.opts.selectFocusClass);
        document.body.addEventListener('click', function () {
            selectedInput.parents(_.opts.parentTarget).removeClass(_.opts.selectFocusClass);
        });
    });

    selectbox.addEventListener('change', function (e) {
        $(this).parents(_.opts.parentTarget).addClass(_.opts.activeClass);
    });
};